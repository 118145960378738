import { api } from './index'
import routes from '../routes'
import { pathToUrl } from '../utils/router'
import {
  EnhancedFeatureInterface,
  WidgetFeatureInterface,
  WidgetInterface,
} from '@enhanci/enhanci-lib'
import { produce } from 'immer'
import { generateCardId } from '@utils/index'
import {
  AiWizardRequest,
  AiWizardResponse,
  WidgetPublishStatusType,
  WidgetWithMetadataInterface,
} from '@interfaces/widgets'
import { useFetch } from '@utils/reactQuery'

export const useFetchWidget = (id: string) =>
  useFetch<WidgetWithMetadataInterface>(pathToUrl(routes.api.widgets.single, { id }))

export const saveWidget = async (widget: WidgetInterface) => {
  const normalizedWidget = produce(widget, draft => {
    draft.config.cards.forEach(card => {
      if (!card.id) {
        card.id = generateCardId()
      }

      if (card.buttonLink.startsWith('https://buy.stripe.com')) {
        card.buttonLinkParams = { client_reference_id: card.id }
      } else {
        delete card.buttonLinkParams
      }
    })
  })

  const { data } = await api.patch<WidgetInterface>(
    pathToUrl(routes.api.widgets.single, { id: widget.id }),
    normalizedWidget,
  )

  return data
}

export const saveWidgetBackgroundColor = async (id: string, backgroundColor: string) => {
  const { data } = await api.patch<WidgetWithMetadataInterface>(
    pathToUrl(routes.api.widgets.single, { id }),
    {
      backgroundColor,
    },
  )

  return data
}

export const deleteWidget = async (id: string) => {
  const { data } = await api.delete<WidgetInterface>(
    pathToUrl(routes.api.widgets.single, { id }),
  )

  return data
}

export const publishWidget = async (id: string) => {
  const { data } = await api.post<WidgetInterface>(
    pathToUrl(routes.api.widgets.publish, { id }),
  )

  return data
}

export const publishPreviewWidget = async (id: string) => {
  const { data } = await api.post<WidgetInterface>(
    pathToUrl(routes.api.widgets.publishPreview, { id }),
  )

  return data
}

export const createWidget = async (widget: WidgetInterface) => {
  const { data } = await api.post<WidgetInterface>(routes.api.widgets.all, widget)

  return data
}

export const copyWidget = async (id: string) => {
  const { data } = await api.post<WidgetInterface>(
    pathToUrl(routes.api.widgets.copy, { id }),
  )

  return data
}

// export const fetchWidgets = async (auth?: string) => {
//   try {
//     const { data } = await api.get<WidgetInterface[]>(routes.api.widgets, auth)
//
//     console.log(data)
//
//     return data
//   } catch (e) {
//     throw e
//   }
// }
//
// export const fetchWidget = async (id, auth) => {
//   try {
//     const { data } = await api.get<WidgetInterface>(
//       pathToUrl(routes.api.widget, { id }),
//       auth,
//     )
//
//     console.log(data)
//
//     return data
//   } catch (e) {
//     throw e
//   }
// }

export const checkPublishStatus = async (id: string, isPreview: boolean) =>
  api.get<{ status: WidgetPublishStatusType }>(
    pathToUrl(routes.api.widgets.checkPublishStatus, { id }),
    undefined,
    {
      isPreview: isPreview ? 'true' : 'false',
    },
  )

export const resetPublishStatus = async (id: string) =>
  api.post<{ status: WidgetPublishStatusType }>(
    pathToUrl(routes.api.widgets.resetPublishStatus, { id }),
  )

export const seenABInitialScreen = async (id: string) =>
  api.patch(pathToUrl(routes.api.abTests.single, { id }), {
    seenInitialScreen: true,
  })

export const useCheckIsInABTest = (id: string) =>
  useFetch(pathToUrl(routes.api.widgets.checkIsInAbTest, { id }))

export const getAiWizardResult = async (data: AiWizardRequest) =>
  api.post<AiWizardResponse[], AiWizardRequest>(routes.api.ai.wizard, data)

export const deleteABTest = async (id: string) => {
  const { data } = await api.delete(pathToUrl(routes.api.abTests.single, { id }))

  return data
}

import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import Box from '@ui/Box'
import { Modal } from 'antd'
import { IoRocketOutline } from 'react-icons/io5'
import Flex from '@ui/Flex'
import TryButton from '@components/Landing/TryButton'
import { AnalyticsEvents, sendAnalyticsEvent } from '@utils/analytics'
import routes from '@routes'

const Trigger = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 10px;
  z-index: 1000;
`

const Img = styled.div`
  position: absolute;
  width: 47%;
  height: 100%;
  background: url('/img/landing/exit-popup.png') no-repeat center center / cover;
  left: 0;
  top: 0;
  border-radius: 8px 0 0 8px;
`

const Left = styled(Box)`
  @media (max-width: 700px) {
    display: none;
  }
`

const Right = styled(Flex)`
  @media (max-width: 700px) {
    width: 100%;
  }
`

const Content = styled(Flex)`
  @media (max-width: 700px) {
    flex-direction: column;
    height: auto;
  }
`

const ExitPopup = () => {
  const [open, setOpen] = useState(false)
  const ref = useRef<NodeJS.Timeout>()

  const onShow = () => {
    if (window.localStorage.getItem('exit_popup_viewed')) {
      return
    }
    setOpen(true)
    sendAnalyticsEvent(AnalyticsEvents.exitPopupOpened)
    window.localStorage.setItem('exit_popup_viewed', 'true')
  }

  useEffect(() => {
    ref.current = setTimeout(() => {
      onShow()
    }, 15000)

    return () => {
      clearTimeout(ref.current)
    }
  }, [])
  return (
    <>
      <Trigger
        onMouseEnter={() => {
          onShow()
        }}
      />
      <Modal open={open} footer={null} width={800} onCancel={() => setOpen(false)}>
        <Content height={400}>
          <Left width="50%">
            <Img />
          </Left>
          <Right width="50%" alignItems="center">
            <Flex justifyContent="center" flexDirection="column" alignItems="center">
              <Box fontSize={28} textAlign="center">
                Want to try our best plan for FREE the first month?
              </Box>
              <TryButton
                label={
                  <>
                    <IoRocketOutline /> Try the 1st month for free
                  </>
                }
                href={`${routes.guestRoutes.signup}?promo=FIRSTMONTH2024`}
                prefetch={false}
                mt={32}
                message="No credit card required"
                onClick={() => {
                  sendAnalyticsEvent(AnalyticsEvents.exitPopupClicked)
                }}
              />
            </Flex>
          </Right>
        </Content>
      </Modal>
    </>
  )
}

export default ExitPopup

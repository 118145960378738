'use client'

import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'next/navigation'
import { WidgetWithMetadataInterface } from '@interfaces/widgets'
import { useFetchWidget } from '@api/widgets'
import { useFetchTemplates } from '@api/templates'
import { produce } from 'immer'

interface BuilderContextInterface {
  widget: WidgetWithMetadataInterface
  setWidget: (widget: WidgetWithMetadataInterface) => void
  refreshWidget: () => void
}

export const BuilderContext = React.createContext<BuilderContextInterface>({
  widget: {} as WidgetWithMetadataInterface,
  setWidget: () => {},
  refreshWidget: () => {},
})

export const BuilderContextProvider = ({ children }: { children: React.ReactNode }) => {
  const params = useParams()
  const id = params!.id as string
  const context = useFetchWidget(id)
  const { data: templates } = useFetchTemplates()

  const { data: widgetData, isLoading, refetch } = context
  const [widget, setWidget] = useState<WidgetWithMetadataInterface | undefined | null>(
    widgetData,
  )

  useEffect(() => {
    if (!isLoading) {
      setWidget(widgetData || null)
    }
  }, [isLoading, widgetData])

  useEffect(() => {
    // if something has changed in the templates we should add default values to existing cards
    if (widget?.template) {
      const templateStyle = templates?.find(t => t.id === widget.template!.id)?.cardsStyle

      if (templateStyle) {
        setWidget(
          produce(widget, draft => {
            draft.config.cards.forEach(card => {
              Object.keys(templateStyle).forEach(key => {
                if (!card.style[key]) {
                  card.style[key] = templateStyle[key]
                }
              })
            })
          }),
        )
      }
    }
  }, [templates, widget?.id])

  const value = useMemo(
    () => ({ widget, setWidget, refreshWidget: refetch }),
    [widget, setWidget, refetch],
  )

  if (isLoading) {
    return <>Loading...</>
  }

  if (!widgetData) {
    return <>Widget not found</>
  }

  return (
    <BuilderContext.Provider value={value as BuilderContextInterface}>
      {children}
    </BuilderContext.Provider>
  )
}

'use client'

import React, { useEffect } from 'react'
import NextHead from 'next/head'
import ExitPopup from '@components/Landing/ExitPopup'
import Head from '@components/Landing/Head'
import { Content } from '@components/Landing/Common'
import Problem from '@components/Landing/Problem'
import Hello from '@components/Landing/Hello'
import HowItWorks from '@components/Landing/HowItWorks'
import WhyWe from '@components/Landing/WhyWe'
import BottomCTA from '@components/Landing/BottomCTA'
import LandingLayout from '@components/Landing/LandingLayout'
import Numbers from '@components/Landing/Numbers'
import SupportedPlatforms from '@components/Landing/SupportedPlatforms'
import StripeIntegration from '@components/Landing/StripeIntegration'
import Reviews from '@components/Landing/Reviews'

type Props = {
  isLoggedIn: boolean
}

const MainLandingPage = ({ isLoggedIn }: Props) => {
  useEffect(() => {
    const ref = localStorage.getItem('ref')

    if (document.referrer && !ref) {
      localStorage.setItem('ref', document.referrer)
    }
  }, [])
  return (
    <LandingLayout
      meta={
        <>
          <NextHead>
            <link
              rel="preload"
              href="https://enhanci-widgets.s3.eu-west-2.amazonaws.com/widgets/widget.c89a1f01-22e4-4859-9ad3-d4435c6abd50.js"
              as="script"
            />
          </NextHead>
        </>
      }
    >
      {!isLoggedIn && <ExitPopup />}
      <Head />
      <Content maxWidth={1200}>
        <Reviews />
        <Problem />
        <Hello />
        <HowItWorks />
        <WhyWe />
        <StripeIntegration />
        <Numbers />
        <SupportedPlatforms />
      </Content>
      <BottomCTA />
    </LandingLayout>
  )
}

export default MainLandingPage

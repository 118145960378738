import { compile } from 'path-to-regexp'
import { useCallback } from 'react'
import { useSearchParams } from 'next/navigation'

export const pathToUrl = (path: string, params: object = {}) => compile(path)(params)

export const fullPath = (path: string) => process.env.NEXT_PUBLIC_DOMAIN + path

export const useQueryString = () => {
  const search = useSearchParams()

  const set = useCallback(
    (name: string, value: string) => {
      const params = new URLSearchParams(search!)
      params.set(name, value)

      return params.toString()
    },
    [search],
  )

  const remove = useCallback(
    (name: string) => {
      const params = new URLSearchParams(search!)
      params.delete(name)

      return params.toString()
    },
    [search],
  )

  return { set, remove }
}

export enum AnalyticsEvents {
  signupEmail = 'signupEmail',
  mainOnboardingOpened = 'mainOnboardingOpened',
  login = 'login',
  widgetCreated = 'widgetCreated',
  clickGoogleSignup = 'clickGoogleSignup',
  helpPopupOpened = 'helpPopupOpened',
  signupError = 'signupError',
  aiWizardClicked = 'aiWizardClicked',
  aiWizardKeep = 'aiWizardKeep',
  aiWizardRollback = 'aiWizardRollback',
  aiWizardStart = 'aiWizardStart',
  aiWizardSuccess = 'aiWizardSuccess',
  aiWizardExcessLimit = 'aiWizardExcessLimit',
  aiWizardFailed = 'aiWizardFailed',
  helpRequestClicked = 'helpRequestClicked',
  exitPopupOpened = 'exitPopupOpened',
  exitPopupClicked = 'exitPopupClicked',
  onboardingCompleted = 'onboardingCompleted',
  googleSitesInstructionClicked = 'googleSitesInstructionClicked',
  htmlInstructionClicked = 'htmlInstructionClicked',
  shopifyInstructionClicked = 'shopifyInstructionClicked',
  weeblyInstructionClicked = 'weeblyInstructionClicked',
  wixInstructionClicked = 'wixInstructionClicked',
  squarespaceInstructionClicked = 'squarespaceInstructionClicked',
  webflowInstructionClicked = 'webflowInstructionClicked',
  elementorInstructionClicked = 'elementorInstructionClicked',
  installPopupOpened = 'installPopupOpened',
  previewClicked = 'previewClicked',
  saveClicked = 'saveClicked',
  publishClicked = 'publishClicked',
}

export const sendAnalyticsEvent = (event: AnalyticsEvents, params?: object) => {
  if ('gtag' in window) {
    // @ts-ignore
    window.gtag('event', event, params)
  } else {
    console.log('Send event', event, params)
  }

  if ('ym' in window) {
    // @ts-ignore
    window.ym(94545832, 'reachGoal', event)
  }
}

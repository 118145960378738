'use client'

import React from 'react'
import styled from 'styled-components'
import Box, { BoxProps } from './Box'
import { transformPropsToStyledComponentProps } from '@utils/designSystem'
import { CustomStyleComponentInterface } from '@interfaces/designSystem'

const GridInner = styled(Box)<CustomStyleComponentInterface>`
  display: grid;
  grid-gap: ${props => (props.$gap ? `${props.$gap}px` : 'none')};
  grid-auto-flow: ${props => props.$flow || 'row'};
  align-content: ${props => props.$alignContent || 'initial'};
  align-items: ${props => props.$alignItems || 'initial'};
  justify-content: ${props => props.$justifyContent || 'initial'};
  justify-items: ${props => props.$justifyItems || 'initial'};
`

export default (props: BoxProps) => (
  <GridInner {...transformPropsToStyledComponentProps(props)} {...props} />
)

import { nanoid } from 'nanoid'

export const hasWindow = () => {
  return typeof window === 'object'
}

export const getSanitizedUrl = (url: string) =>
  url.replaceAll(/[^-A-Za-z0-9+&@#/%?=~_|!:,.;\(\)]/g, '-').toLowerCase()

export const isMobile = hasWindow() && window.matchMedia('(max-width: 768px)').matches

export const copyToClipboard = (text: string) => navigator.clipboard.writeText(text)

export const generateCardId = () => nanoid(8)

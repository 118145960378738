import React from 'react'
import styled from 'styled-components'
import Box from '@ui/Box'
import Grid from '@ui/Grid'
import { AiFillStar } from 'react-icons/ai'
import Flex from '@ui/Flex'
import { Color } from '@interfaces/designSystem'

const Rate = () => {
  return (
    <Flex mb={16}>
      <Grid gap={4} flow="column">
        <AiFillStar color={Color.Yellow} />
        <AiFillStar color={Color.Yellow} />
        <AiFillStar color={Color.Yellow} />
        <AiFillStar color={Color.Yellow} />
        <AiFillStar color={Color.Yellow} />
      </Grid>
    </Flex>
  )
}

const Item = styled(Box)`
  padding: 16px;
  border: 1px solid #5c64ff;
  border-radius: 8px;
  margin: 0 16px;

  @media (max-width: 768px) {
    margin: 16px 0;
  }
`

const Container = styled(Flex)`
  justify-content: space-between;
  margin-top: 26px;

  @media (max-width: 768px) {
    display: block;
  }
`

const Link = styled.a`
  color: ${Color.Blue_70};
`

type Props = {
  text: React.ReactNode
  name: string
  url: string
  site: string
}

const Review = ({ site, url, text, name }: Props) => {
  return (
    <Item>
      <Rate />
      <Box>{text}</Box>
      <Box mt={16}>
        {name} on{' '}
        <Link href={url} target="_blank">
          {site}
        </Link>
      </Box>
    </Item>
  )
}

const Reviews = () => {
  return (
    <Container>
      <Review
        name="Marcos"
        text={`"Hands down the best app for pricing tables! It's far superior to Elfsight. The support team is always available when you need them, and customization is incredibly easy."`}
        url="https://www.producthunt.com/products/enhanci/reviews"
        site="Producthunt"
      />
      <Review
        name="Sergey"
        text={`Nice service to create pricing tables with ability to connect stripe analytics. A bit luck of customization, but very structured and easy to use. The support listens to the feedback and adds the functions accordingly.`}
        url="https://saasmantra.com/deals/enhanci"
        site="Saasmantra"
      />
      <Review
        name="Maroš"
        text={
          <>
            I am very happy and satisfied. Enhanci, thank you. For a very long time I
            struggled with the price tags on the site. And even worse was my FAQ. Believe
            me, I bought ENHANCI and within 20 minutes I had finished 2 beautiful tables
            with prices and descriptions + FAQ -{' '}
            <Link
              target="_blank"
              href="https://www.canva.com/design/DAGB6QIVU6A/mQMHb-Ps2rh1KavTrPhXTw/view?utm_content=DAGB6QIVU6A&utm_campaign=designshare&utm_medium=link&utm_source=editor"
            >
              link
            </Link>{' '}
            to my first work
          </>
        }
        url="https://saasmantra.com/deals/enhanci"
        site="Saasmantra"
      />
    </Container>
  )
}

export default Reviews

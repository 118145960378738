import React from 'react'
import styled from 'styled-components'
import Box from '@ui/Box'
import NextHead from 'next/head'
import Grid from '@ui/Grid'
import routes from '@routes'

const Section = styled.section`
  margin-top: 200px;
  max-width: 1200px;

  @media (max-width: 768px) {
    margin-top: 100px;
  }
`

const Title = styled.h2`
  font-size: 70px;
  font-weight: 500;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 50px;
  }
`

const Subtitle = styled.h2`
  font-size: 42px;
  text-align: center;
  color: #40e0ec;
  font-weight: 400;

  @media (max-width: 768px) {
    font-size: 32px;
  }
`

const Item = styled.a`
  background-color: #eafdff;
  border-radius: 15px;
  height: 115px;
  width: 285px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Container = styled(Grid)`
  justify-content: center;
  grid-template-columns: repeat(auto-fill, 285px);
`

const Img = styled.img<{ $height?: string; $width?: string }>`
  height: ${props => props.$height || '35px'};
  width: ${props => props.$width};
`

const SupportedPlatforms = () => {
  return (
    <>
      <NextHead>
        <link rel="preload" href="/img/landing/ab.png" as="image" />
        <link rel="preload" href="/img/landing/modern-design.png" as="image" />
        <link rel="preload" href="/img/landing/analytics.png" as="image" />
        <link rel="preload" href="/img/landing/extras.png" as="image" />
      </NextHead>
      <Section id="supported-platforms">
        <Title>Supported platforms</Title>
        <Subtitle>You can easily add your pricing table to multiple platforms</Subtitle>

        <Container gap={20}>
          <Item href={routes.publicRoutes.landingPricingTableForWix}>
            <Img src="/img/platforms/wix.svg" alt="Pricing table for Wix" />
          </Item>
          <Item href={routes.publicRoutes.landingPricingTableForWebflow}>
            <Img src="/img/platforms/webflow.svg" alt="Pricing table for Webflow" />
          </Item>
          <Item href={routes.publicRoutes.landingPricingTableForSquarespace}>
            <Img
              src="/img/platforms/squarespace.svg"
              alt="Pricing table for Squarespace"
              $width="100%"
              $height="100%"
            />
          </Item>
          <Item href={routes.publicRoutes.landingPricingTableForShopify}>
            <Img src="/img/platforms/shopify.svg" alt="Pricing table for Shopify" />
          </Item>
          <Item href={routes.publicRoutes.landingPricingTableForElementor}>
            <Img src="/img/platforms/elementor.svg" alt="Pricing table for Elementor" />
          </Item>
          <Item href={routes.publicRoutes.landingPricingTableForWeebly}>
            <Img
              src="/img/platforms/weebly.svg"
              alt="Pricing table for Weebly"
              $height="50px"
            />
          </Item>
          <Item href={routes.publicRoutes.landingPricingTableForHTML}>
            <Img
              src="/img/platforms/html.png"
              alt="Pricing table using HTML"
              $height="60px"
            />
          </Item>
          <Item href={routes.publicRoutes.landingPricingTableForGoogleSites}>
            <Img
              src="/img/platforms/google-sites.png"
              alt="Pricing table for Google Sites"
              $height="60px"
            />
          </Item>
        </Container>
      </Section>
    </>
  )
}

export default SupportedPlatforms

import { TemplateInterface } from '../interfaces/templates'
import routes from '../routes'
import { useFetch } from '../utils/reactQuery'
import { api } from './index'
import { TemplateType, WidgetConfigInterface } from '@enhanci/enhanci-lib'

export const useFetchTemplates = () =>
  useFetch<TemplateInterface[]>(routes.api.templates, undefined, undefined, {
    staleTime: 1000 * 30,
    cacheTime: 1000 * 30,
  })

export const getDefaultConfig = (templateId: TemplateType) =>
  api.get<WidgetConfigInterface>(routes.api.defaultConfig, undefined, {
    templateId,
  })

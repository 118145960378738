import React, { RefObject, useContext, useEffect, useState } from 'react'
import { BuilderContext } from '../app/builder/[id]/utils'
import { useGetProfile } from '@api/users'

export const useIsDemo = () => {
  const { widget } = useContext(BuilderContext)
  return widget.id === 'demo'
}

export const useIsVisible = (ref: RefObject<HTMLElement>) => {
  const [isIntersecting, setIntersecting] = useState(false)

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) =>
      setIntersecting(entry.isIntersecting),
    )

    if (ref.current) {
      observer.observe(ref.current)
    }

    return () => {
      observer.disconnect()
    }
  }, [ref])

  return isIntersecting
}

export const useCouldSeeStripe = () => {
  // const { data: profile } = useGetProfile()

  return true
}

import React from 'react'
import styled from 'styled-components'
import Box from '@ui/Box'
import { Color } from '@interfaces/designSystem'

const Wrap = styled(Box)`
  background: #002b2f url('/img/landing/numbers-bg.svg') no-repeat left top;
  padding: 120px 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 150px;

  @media (max-width: 1050px) {
    flex-direction: column;
    padding: 70px 30px;
  }
`

const Item = styled(Box)`
  @media (max-width: 1050px) {
    margin-bottom: 32px;
  }
`

const Numbers = () => {
  return (
    <Wrap borderRadius={40}>
      <Item>
        <Box color={Color.Blue_60} fontSize={86} fontWeight="700">
          89ms
        </Box>
        <Box fontSize={20}>to load and show a pricing table</Box>
      </Item>

      <Item>
        <Box color={Color.Blue_60} fontSize={86} fontWeight="700">
          99.99%
        </Box>
        <Box fontSize={20}>uptime for serving pricing tables</Box>
      </Item>

      <Item>
        <Box color={Color.Blue_60} fontSize={86} fontWeight="700">
          12
        </Box>
        <Box fontSize={20}>modern pricing table designs</Box>
      </Item>
    </Wrap>
  )
}

export default Numbers

import React, { useRef } from 'react'
import styled, { css, keyframes } from 'styled-components'
import Box from '@ui/Box'
import { BoldBlue } from '@components/Landing/Common'
import { useIsVisible } from '@utils/hooks'

const keyframes1 = keyframes`
  from {
    transform: rotate(-45deg) scale(1.1)
  }

  to {
    transform: rotate(0) scale(1);
  }
`

const keyframes2 = keyframes`
  from {
    transform: rotate(45deg) scale(1.1)
  }

  to {
    transform: rotate(0) scale(1);
  }
`

const keyframes3 = keyframes`
  0% {
    transform: scale(1.2)
  }

  20% {
    transform: scale(0.8)
  }

  60% {
    transform: scale(1.2)
  }
  

  100% {
    transform: scale(1)
  }
`

const keyframes4 = keyframes`
  from {
    transform: scaleY(1.3) rotate(-25deg)
  }
  
  to {
    transform: scaleY(1)
  }
`

const animation1 = css`
  animation: ${keyframes1} 1000ms ease 1;
`

const animation2 = css`
  animation: ${keyframes2} 1000ms ease 1;
`

const animation3 = css`
  animation: ${keyframes3} 1000ms ease 1;
`

const animation4 = css`
  animation: ${keyframes4} 1000ms ease 1;
`

const Problem = styled.section`
  margin-top: 200px;

  @media (max-width: 768px) {
    margin-top: 100px;
  }
`

const ProblemItemContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const ProblemItem = styled.div`
  padding: 40px 20px;
  margin-top: 180px;

  &:nth-child(even) {
    ${ProblemItemContent} {
      flex-direction: row-reverse;
    }
  }

  @media (max-width: 768px) {
    padding: 20px 10px;
    margin-top: 80px;
  }
`
const ProblemItemTitle = styled.h3`
  color: #ededed;
  font-size: 42px;
  font-weight: 400;
  line-height: normal;
  margin: 30px 0 0 0;
  max-width: 730px;

  @media (max-width: 768px) {
    font-size: 32px;
  }
`

const ProblemItemText = styled.div`
  color: #e1e1e1;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.3;
  margin-top: 10px;
`

const ProblemItemIcon1p1 = styled.svg<{ $visible: boolean }>`
  position: relative;
  top: -50px;
  ${({ $visible }) => $visible && animation1}
`
const ProblemItemIcon1p2 = styled.svg<{ $visible: boolean }>`
  position: relative;
  top: 50px;
  left: -40px;
  ${({ $visible }) => $visible && animation2}
`
const ProblemItemIcon2p1 = styled.svg<{ $visible: boolean }>`
  position: relative;
  top: -50px;
  ${({ $visible }) => $visible && animation3}
`
const ProblemItemIcon2p2 = styled.svg<{ $visible: boolean }>`
  position: relative;
  top: 50px;
  ${({ $visible }) => $visible && animation4}
`

const ProblemItemIcon = styled.svg`
  width: 70px;
`

const Anchor = styled.div`
  position: relative;
  top: 80px;
`

const ProblemComponent = () => {
  const anchor1 = useRef<HTMLDivElement | null>(null)
  const anchor2 = useRef<HTMLDivElement | null>(null)

  const anchor1visible = useIsVisible(anchor1)
  const anchor2visible = useIsVisible(anchor2)

  return (
    <Problem>
      <ProblemItem>
        <ProblemItemContent>
          <ProblemItemIcon1p1
            xmlns="http://www.w3.org/2000/svg"
            width="161"
            height="161"
            viewBox="0 0 161 161"
            fill="none"
            $visible={anchor1visible}
          >
            <g clipPath="url(#clip0_548_2527)">
              <path
                opacity="0.5"
                d="M102.542 125.199C127.295 112.958 137.439 82.9693 125.199 58.216C112.959 33.4627 82.9696 23.3188 58.2163 35.559C33.463 47.7991 23.3191 77.7882 35.5592 102.541C47.7993 127.295 77.7884 137.439 102.542 125.199Z"
                stroke="#40E0EC"
                strokeWidth="8"
              />
              <path
                d="M62.4515 89.2438L68.0911 78.0883L55.8027 75.7979"
                stroke="#40E0EC"
                strokeWidth="8"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M98.3061 71.5134L86.0178 69.223L91.6573 58.0675"
                stroke="#40E0EC"
                strokeWidth="8"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M102.69 91.658L95.9919 89.3923L93.7262 96.0905L87.028 93.8248L84.7623 100.523L78.064 98.2574L75.7983 104.956"
                stroke="#40E0EC"
                strokeWidth="8"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_548_2527">
                <rect
                  width="120"
                  height="120"
                  fill="white"
                  transform="translate(0 53.1904) rotate(-26.3117)"
                />
              </clipPath>
            </defs>
          </ProblemItemIcon1p1>
          <ProblemItemIcon1p2
            xmlns="http://www.w3.org/2000/svg"
            width="143"
            height="143"
            viewBox="0 0 143 143"
            fill="none"
            $visible={anchor1visible}
          >
            <g clipPath="url(#clip0_548_2532)">
              <path
                d="M52.4023 87.6475C57.2939 88.6834 61.6209 89.5997 67.0769 90.7552C72.5329 91.9107 76.8599 92.827 81.7514 93.8629"
                fill="#40E0EC"
              />
              <path
                d="M52.4023 87.6475C57.2939 88.6834 61.6209 89.5997 67.0769 90.7552C72.5329 91.9107 76.8599 92.827 81.7514 93.8629"
                stroke="#40E0EC"
                strokeWidth="8"
                strokeLinecap="round"
              />
              <path
                d="M85.8951 74.2974C88.5966 74.8695 91.4823 72.0483 92.3405 67.996C93.1987 63.9437 91.7043 60.1949 89.0028 59.6228C86.3013 59.0507 83.4156 61.8719 82.5575 65.9242C81.6993 69.9764 83.1936 73.7252 85.8951 74.2974Z"
                fill="#40E0EC"
              />
              <path
                d="M56.5455 68.0815C59.247 68.6537 62.1327 65.8324 62.9909 61.7802C63.849 57.7279 62.3547 53.9791 59.6532 53.407C56.9517 52.8349 54.066 55.6561 53.2078 59.7084C52.3497 63.7606 53.844 67.5094 56.5455 68.0815Z"
                fill="#40E0EC"
              />
              <path
                opacity="0.5"
                d="M22.3049 60.8302C27.1883 37.7713 29.6299 26.2419 38.3105 20.5956C46.991 14.9492 58.5203 17.3908 81.5792 22.2742C104.638 27.1575 116.168 29.5992 121.814 38.2797C127.46 46.9602 125.018 58.4896 120.135 81.5484C115.252 104.607 112.81 116.137 104.129 121.783C95.4493 127.429 83.9195 124.988 60.8609 120.104C37.8021 115.221 26.2727 112.779 20.6264 104.099C14.9799 95.4185 17.4216 83.8887 22.3049 60.8302Z"
                stroke="#40E0EC"
                strokeWidth="8"
              />
            </g>
            <defs>
              <clipPath id="clip0_548_2532">
                <rect
                  width="120"
                  height="120"
                  fill="white"
                  transform="translate(24.9531 0.0605469) rotate(11.9573)"
                />
              </clipPath>
            </defs>
          </ProblemItemIcon1p2>
          <ProblemItemTitle>
            Are you tired of boring pricing plan design that fails to{' '}
            <BoldBlue>impress your clients</BoldBlue>?
          </ProblemItemTitle>
        </ProblemItemContent>
        <Anchor ref={anchor1} />
      </ProblemItem>
      <ProblemItem>
        <ProblemItemContent>
          <ProblemItemIcon2p1
            xmlns="http://www.w3.org/2000/svg"
            width="137"
            height="136"
            viewBox="0 0 137 136"
            fill="none"
            $visible={anchor2visible}
          >
            <path
              d="M56.7332 59.5263C67.6646 57.9415 75.2416 47.7951 73.6568 36.8637C72.072 25.9323 61.9257 18.3553 50.9943 19.9401C40.0628 21.5249 32.4859 31.6712 34.0706 42.6027C35.6554 53.5341 45.8018 61.111 56.7332 59.5263Z"
              stroke="#40E0EC"
              strokeWidth="8"
            />
            <path
              opacity="0.5"
              d="M101.699 90.8987C103.482 103.197 104.927 113.166 65.3413 118.905C25.7551 124.644 24.3098 114.675 22.527 102.377C20.7441 90.0787 37.0221 77.54 58.885 74.3704C80.7479 71.2009 99.9164 78.6008 101.699 90.8987Z"
              stroke="#40E0EC"
              strokeWidth="8"
            />
            <path
              d="M100.294 46.3575L98.0417 49.3556C98.84 49.9553 99.8442 50.212 100.832 50.0687C101.821 49.9255 102.711 49.3942 103.306 48.5925L100.294 46.3575ZM100.402 63.0081C97.7287 61.5198 95.3478 60.3644 93.3244 58.7131C91.4495 57.1828 90.2513 55.4942 89.9184 53.1979L82.496 54.274C83.1763 58.9667 85.738 62.2022 88.5825 64.5236C91.2787 66.7245 94.5763 68.3487 96.7543 69.5613L100.402 63.0081ZM89.9184 53.1979C89.6229 51.1593 90.5706 49.3392 91.9945 48.4453C93.2272 47.6713 95.3162 47.3076 98.0417 49.3556L102.547 43.3594C97.6188 39.6567 92.157 39.4877 88.0069 42.0933C84.0471 44.5791 81.7783 49.3235 82.496 54.274L89.9184 53.1979ZM96.7543 69.5613C97.5731 70.0165 98.6345 70.6083 99.7251 71.0323C100.817 71.457 102.303 71.8655 103.958 71.6257L102.882 64.2033C103.052 64.1786 102.973 64.2481 102.443 64.0424C101.912 63.836 101.295 63.5051 100.402 63.0081L96.7543 69.5613ZM110.278 67.6007C112.022 65.8196 114.723 63.3258 116.683 60.4498C118.751 57.4162 120.288 53.5865 119.608 48.8937L112.186 49.9698C112.519 52.2661 111.849 54.2254 110.486 56.2252C109.015 58.3831 107.06 60.167 104.92 62.3532L110.278 67.6007ZM119.608 48.8937C118.89 43.9433 115.368 40.0385 110.865 38.7795C106.146 37.4597 100.957 39.1728 97.283 44.1225L103.306 48.5925C105.337 45.8548 107.444 45.6104 108.845 46.0024C110.465 46.4551 111.89 47.9312 112.186 49.9698L119.608 48.8937ZM104.92 62.3532C104.204 63.0834 103.707 63.5759 103.256 63.9247C102.807 64.2722 102.711 64.2279 102.882 64.2033L103.958 71.6257C105.612 71.3858 106.921 70.5721 107.847 69.8548C108.773 69.1386 109.622 68.2697 110.278 67.6007L104.92 62.3532Z"
              fill="#40E0EC"
            />
          </ProblemItemIcon2p1>
          <ProblemItemIcon2p2
            xmlns="http://www.w3.org/2000/svg"
            width="151"
            height="151"
            viewBox="0 0 151 151"
            fill="none"
            $visible={anchor2visible}
          >
            <g clipPath="url(#clip0_592_2375)">
              <path
                opacity="0.5"
                d="M28.2003 60.8089C35.1493 38.2863 38.6238 27.025 47.7795 22.1869C56.9351 17.3488 68.1964 20.8233 90.719 27.7724C113.241 34.7213 124.503 38.1959 129.341 47.3515C134.179 56.5072 130.705 67.7685 123.756 90.2911C116.807 112.813 113.332 124.075 104.176 128.913C95.0208 133.751 83.7592 130.277 61.2368 123.328C38.7142 116.379 27.4529 112.904 22.6149 103.748C17.7767 94.5929 21.2513 83.3312 28.2003 60.8089Z"
                stroke="#40E0EC"
                strokeWidth="8"
              />
              <path
                d="M43.2447 96.8456L56.5117 53.8457"
                stroke="#40E0EC"
                strokeWidth="8"
                strokeLinecap="round"
              />
              <path
                d="M67.1329 104.216L84.8223 46.8828"
                stroke="#40E0EC"
                strokeWidth="8"
                strokeLinecap="round"
              />
              <path
                d="M91.022 111.587L98.3926 87.6982"
                stroke="#40E0EC"
                strokeWidth="8"
                strokeLinecap="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_592_2375">
                <rect
                  width="120"
                  height="120"
                  fill="white"
                  transform="translate(36.334 0.527344) rotate(17.1469)"
                />
              </clipPath>
            </defs>
          </ProblemItemIcon2p2>
          <ProblemItemTitle>
            Want to discover which pricing table option attracts{' '}
            <BoldBlue>the most clicks</BoldBlue>?
          </ProblemItemTitle>
        </ProblemItemContent>
        <Anchor ref={anchor2} />
      </ProblemItem>
    </Problem>
  )
}

export default ProblemComponent
